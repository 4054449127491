// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdHoc Component shallow snapshot render 1`] = `
<div
  className="adhoc-fees"
>
  <div
    className="row adhoc-fees__header-container"
  >
    <div
      className="col-md-1"
    />
    <div
      className="col-md-11"
    >
      <div
        className="row"
      >
        <div
          className="col-md-3"
        >
          <strong>
            Fee Name
          </strong>
        </div>
        <div
          className="col-md-2"
        >
          <strong>
            Amount
          </strong>
        </div>
        <div
          className="col-md-2"
        >
          <strong>
            Frequency
          </strong>
        </div>
        <div
          className="col-md-2"
        >
          <strong>
            Effective
          </strong>
        </div>
      </div>
    </div>
  </div>
  <div
    className="form-group"
  >
    <div
      className="form-group adhoc-fees__fee-row "
      key="1"
    >
      <span
        className="col-md-1 control-label"
      >
        <strong>
          Fee #
          1
        </strong>
      </span>
      <div
        className="col-md-11"
      >
        <div
          className="row"
        >
          <div
            className="col-md-3 has-error"
          >
            <select
              className="form-control "
              data-field="name"
              id="plan_ad_hoc_fees_attributes_0_name"
              name="plan[ad_hoc_fees_attributes][0][name]"
              onChange={[Function]}
              readOnly={false}
              value="foo"
            >
              <option
                key="pci_non_compliance"
                value="pci_non_compliance"
              >
                PCI Non-compliance Fee
              </option>
            </select>
            <span
              className="help-block"
            >
              foo, bar
            </span>
          </div>
          <div
            className="col-md-2 "
          >
            <div
              className="input-group"
            >
              <span
                className="input-group-addon"
              >
                $
              </span>
              <t
                className="form-control"
                data-field="rate"
                id="plan_ad_hoc_fees_attributes_0_rate"
                mask={[Function]}
                name="plan[ad_hoc_fees_attributes][0][rate]"
                onChange={[Function]}
                readOnly={false}
                render={[Function]}
                required={true}
                type="text"
              />
            </div>
          </div>
          <div
            className="col-md-2 has-error"
          >
            <select
              className="form-control "
              data-field="frequency"
              id="plan_ad_hoc_fees_attributes_0_frequency"
              name="plan[ad_hoc_fees_attributes][0][frequency]"
              onChange={[Function]}
              value="very foo"
            >
              <option
                value="MONTHLY"
              >
                Monthly
              </option>
              <option
                value="YEARLY"
              >
                Yearly
              </option>
              <option
                value="ONE_TIME"
              >
                One Time
              </option>
            </select>
            <span
              className="help-block"
            >
              foo, bar
            </span>
          </div>
          <div>
            <div
              className="col-md-2 "
            >
              <select
                className="form-control "
                data-field="effective_month"
                id="plan_ad_hoc_fees_attributes_0_effective_month"
                name="plan[ad_hoc_fees_attributes][0][effective_month]"
                onChange={[Function]}
                value="2"
              >
                <option
                  value=""
                />
                <option
                  key="0"
                  value={1}
                >
                  January
                </option>
                <option
                  key="1"
                  value={2}
                >
                  February
                </option>
                <option
                  key="2"
                  value={3}
                >
                  March
                </option>
                <option
                  key="3"
                  value={4}
                >
                  April
                </option>
                <option
                  key="4"
                  value={5}
                >
                  May
                </option>
                <option
                  key="5"
                  value={6}
                >
                  June
                </option>
                <option
                  key="6"
                  value={7}
                >
                  July
                </option>
                <option
                  key="7"
                  value={8}
                >
                  August
                </option>
                <option
                  key="8"
                  value={9}
                >
                  September
                </option>
                <option
                  key="9"
                  value={10}
                >
                  October
                </option>
                <option
                  key="10"
                  value={11}
                >
                  November
                </option>
                <option
                  key="11"
                  value={12}
                >
                  December
                </option>
              </select>
            </div>
            <div
              className="col-md-2 "
            >
              <select
                className="form-control "
                data-field="effective_year"
                id="plan_ad_hoc_fees_attributes_0_effective_year"
                name="plan[ad_hoc_fees_attributes][0][effective_year]"
                onChange={[Function]}
                value="2"
              >
                <option
                  value=""
                />
                <option
                  key="0"
                  value={2}
                >
                  2
                </option>
                <option
                  key="1"
                  value={2025}
                >
                  2025
                </option>
                <option
                  key="2"
                  value={2026}
                >
                  2026
                </option>
                <option
                  key="3"
                  value={2027}
                >
                  2027
                </option>
              </select>
            </div>
          </div>
          <div
            className="col-md-1"
          >
            <input
              id="plan_ad_hoc_fees_attributes_0_id"
              name="plan[ad_hoc_fees_attributes][0][id]"
              type="hidden"
              value={1}
            />
            <span
              className="glyphicon glyphicon-trash"
              onClick={[Function]}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-2 col-md-offset-1"
    />
  </div>
</div>
`;

exports[`AdHoc Component shallow snapshot render with no errors and destroy 1`] = `
<div
  className="adhoc-fees"
>
  <div
    className="row adhoc-fees__header-container"
  >
    <div
      className="col-md-1"
    />
    <div
      className="col-md-11"
    >
      <div
        className="row"
      >
        <div
          className="col-md-3"
        >
          <strong>
            Fee Name
          </strong>
        </div>
        <div
          className="col-md-2"
        >
          <strong>
            Amount
          </strong>
        </div>
        <div
          className="col-md-2"
        >
          <strong>
            Frequency
          </strong>
        </div>
      </div>
    </div>
  </div>
  <div
    className="form-group"
  >
    <div
      className="form-group adhoc-fees__fee-row adhoc-fees__fee-row--marked-as-deleted"
      key="0"
    >
      <span
        className="col-md-1 control-label"
      >
        <strong>
          Fee #
          1
        </strong>
      </span>
      <div
        className="col-md-11"
      >
        <div
          className="row"
        >
          <div
            className="col-md-3 "
          >
            <select
              className="form-control readonly"
              data-field="name"
              id="plan_ad_hoc_fees_attributes_0_name"
              name="plan[ad_hoc_fees_attributes][0][name]"
              onChange={[Function]}
              readOnly={true}
              value="foo"
            >
              <option
                key="pci_non_compliance"
                value="pci_non_compliance"
              >
                PCI Non-compliance Fee
              </option>
            </select>
          </div>
          <div
            className="col-md-2 "
          >
            <div
              className="input-group"
            >
              <span
                className="input-group-addon"
              >
                $
              </span>
              <t
                className="form-control"
                data-field="rate"
                id="plan_ad_hoc_fees_attributes_0_rate"
                mask={[Function]}
                name="plan[ad_hoc_fees_attributes][0][rate]"
                onChange={[Function]}
                readOnly={true}
                render={[Function]}
                required={true}
                type="text"
              />
            </div>
          </div>
          <div
            className="col-md-2 "
          >
            <select
              className="form-control readonly"
              data-field="frequency"
              id="plan_ad_hoc_fees_attributes_0_frequency"
              name="plan[ad_hoc_fees_attributes][0][frequency]"
              onChange={[Function]}
              readOnly={true}
              value="very foo"
            >
              <option
                value="MONTHLY"
              >
                Monthly
              </option>
              <option
                value="YEARLY"
              >
                Yearly
              </option>
              <option
                value="ONE_TIME"
              >
                One Time
              </option>
            </select>
          </div>
          <div
            className="col-md-1"
          >
            <input
              data-field="_destroy"
              id="plan_ad_hoc_fees_attributes_0__destroy"
              name="plan[ad_hoc_fees_attributes][0][_destroy]"
              onChange={[Function]}
              type="hidden"
              value={true}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-2 col-md-offset-1"
    >
      <a
        onClick={[Function]}
        role="button"
      >
        + Add 
        another 
        fee
      </a>
    </div>
  </div>
</div>
`;

import React from 'react';
import { render, screen } from '@testing-library/react';
import SelfServiceBanner from '..';
import userEvent from '@testing-library/user-event';

describe('SelfServiceBanner', () => {
  const __location = window.location;

  afterEach(() => {
    window.sessionStorage.clear();
    window.location = __location;
  });

  test('renders self service banner', () => {
    render(<SelfServiceBanner />);
    expect(screen.getByText('Please verify your business information')).toBeVisible();
  });

  test('clicking verify now button redirects', () => {
    delete window.location;
    window.location = {assign: jest.fn(), pathname: '/'};
    render(<SelfServiceBanner />);
    const btn = screen.getByText('Verify Now');
    userEvent.click(btn);
    expect(window.location.assign).toHaveBeenCalledWith('/self_service');
  });
});
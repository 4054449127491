import {
  isTrustAccount,
  getMonetaryDetails,
  gatherPaymentValues,
  defaultMonetaryOutput,
  getInvoiceDetails
} from '../utils/helpers';
import {
  failedInvoicePayment,
  authorizedInvoice,
  completedInvoice,
  invoiceWithPayment,
  invoiceWithoutPayment
} from './fixtures';

const expectedReturnWithoutPayment = [{
  amountPaid: {
    currency: 'CAD',
    subTotal: 23900,
    showAmount: true
  },
  bankDetails: {
    bankAccount: undefined,
    bankName: undefined,
    isTrustAccount: false
  },
  depositDetails: {
    currency: undefined,
    surchargeAmount: undefined
  },
  id: 'i_4C8UmxngLgXLAY30ESSwA',
  invoiceDate: '2024-07-26T20:19:50.917Z',
  nameReference: {
    displayName: 'Foo Bar',
    reference: 'foobar1'
  },
  paymentTotal: {
    amount: undefined,
    currency: undefined,
    failedTransaction: undefined
  },
  timestamp: '2024-07-26T20:19:50.917Z'
}];

const expectedReturnWithPayment = [{
  amountPaid: {
    currency: 'CAD',
    subTotal: 23900,
    showAmount: true
  },
  bankDetails: {
    bankAccount: '*******6789',
    bankName: 'Bank of Canada',
    isTrustAccount: false
  },
  depositDetails: {
    currency: 'CAD',
    surchargeAmount: 717
  },
  id: 'i_4C8UmxngLgXLAY30ESSwA',
  invoiceDate: '2024-07-26T20:19:50.917Z',
  nameReference: {
    displayName: 'Foo Bar',
    reference: 'foobar1'
  },
  paymentTotal: {
    amount: 24617,
    currency: 'CAD',
    failedTransaction: false
  },
  timestamp: '2024-07-26T20:19:50.917Z'
}];

describe('isTrustAccount', () => {
  it('returns true if bankAccountType equals "iolta"', () => {
    const paymentWithTrustAccount = { payment: { bankAccountType: 'iolta' } };
    const paymentWithoutTrustAccount = { payment: { bankAccountType: 'adyen' } };
    expect(isTrustAccount(paymentWithTrustAccount)).toBe(true);
    expect(isTrustAccount(paymentWithoutTrustAccount)).toBe(false);
  });

  it('returns false if invoice lacks a successful payment', () => {
    expect(isTrustAccount(undefined)).toBe(false);
  });
});

describe('getMonetaryDetails', () => {
  it('returns a default object with undefined values if invoice is undefined or lacking keys', () => {
    expect(getMonetaryDetails()).toEqual(defaultMonetaryOutput);
    expect(getMonetaryDetails({})).toEqual(defaultMonetaryOutput);
  });

  it('returns a default object with undefined values if invoice payment status is not "authorized" or "completed"', () => {
    expect(getMonetaryDetails(failedInvoicePayment)).toEqual(defaultMonetaryOutput);
  });

  it('returns an object with values if invoice payment status is "authorized" or "completed"', () => {
    const invoiceDetails = getInvoiceDetails(authorizedInvoice, 'transactionId');
    const authorizedMonetaryDetails = {
      bankDetails: {
        bankAccount: '*******6789',
        bankName: 'Wells Fargo',
        isTrustAccount: true
      },
      paymentTotal: {
        amount: 10300,
        currency: 'USD',
        failedTransaction: false
      },
      depositDetails: {
        currency: 'USD',
        surchargeAmount: 300
      }
    };

    expect(getMonetaryDetails(authorizedInvoice, invoiceDetails)).toEqual(authorizedMonetaryDetails);
    expect(getMonetaryDetails(completedInvoice, invoiceDetails)).toEqual(authorizedMonetaryDetails);
  });
});

describe('getInvoiceDetails', () => {
  it('returns null if invoice lacks a successful payment', () => {
    const invoiceDetails = {
      amount: undefined,
      surcharge: undefined,
      status: 'Failed'
    };

    expect(getInvoiceDetails(failedInvoicePayment, 'transactionId')).toStrictEqual(invoiceDetails);
  });

  it('returns an object with invoiceAmount and invoiceSurcharge if invoice has a successful payment', () => {
    const invoicePayment = {
      amount: 10000,
      surcharge: 300,
      status: 'authorized'
    };

    expect(getInvoiceDetails(authorizedInvoice, 'transactionId')).toEqual(invoicePayment);
  });
});

describe('gatherPaymentValues', () => {
  it('returns an empty array if invoices array is empty', () => {
    expect(gatherPaymentValues([])).toEqual([]);
  });

  it('returns an array with single invoice having undefined monetary values if a payment is not present', () => {
    expect(gatherPaymentValues([invoiceWithoutPayment])).toEqual(expectedReturnWithoutPayment);
  });

  it('returns an array with single invoice having defined monetary values if a payment is present', () => {
    expect(gatherPaymentValues([invoiceWithPayment])).toEqual(expectedReturnWithPayment);
  });

  it('returns an array of invoices that have key-value pairs', () => {
    expect(gatherPaymentValues([invoiceWithPayment, {}, invoiceWithoutPayment, {}]).length).toEqual(2);
    expect(gatherPaymentValues([invoiceWithPayment, {}, invoiceWithoutPayment, {}]))
      .toEqual([expectedReturnWithPayment[0], expectedReturnWithoutPayment[0]]);
  });
});